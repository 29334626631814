@import "~@blueprintjs/core/lib/css/blueprint.css";

:root {
  /* Paletter */

  --usafa-blue: rgb(10, 48, 101);
  --phthalo-green: #0b372c;
  --maximum-red: rgb(180, 19, 4);

  /* Var Colors*/
  --title-color: var(--phthalo-green);
  --men-color: var(--usafa-blue);
  --women-color: var(--maximum-red);

  --people-color-rgb-nums: 227, 187, 34;
  --people-color: rgba(var(--people-color-rgb-nums), 1);
  --families-color: rgb(50, 135, 132);

  --infrastructure-color-rgb-nums: 158, 203, 241;
  --infrastructure-color: rgba(var(--infrastructure-color-rgb-nums), 1);

  --network-edges-color: rgb(117, 117, 117);
  --lenguas-occidentales-color: #b58200;
  --lenguas-tradicionales-color: #186251;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Parameter boxes */
.parameterBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.parameterBoxCenterd {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.columnParameterBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: column;
}

.disabled-link {
  pointer-events: none;
}

.column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  gap: 10px;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#outline {
  border: 2px solid rgb(174, 174, 174);
  border-radius: 30px;
}

#rotated-label {
  transform: rotate(270deg);
  font-size: 1.5em;
}

.stretch-1-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 8.3%;
}

.stretch-2-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16.6%;
}

.stretch-3-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
}

.stretch-4-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33%;
}

.stretch-5-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 41.6%;
}

.stretch-6-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}

.stretch-7-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 58.3%;
}

.stretch-8-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66%;
}

.stretch-9-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
}

.stretch-10-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 83.3%;
}

.stretch-11-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 91.6%;
}

.stretch-12-12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.indicadorButton {
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  opacity: 0.6;
}

img.indicadorButton:hover {
  opacity: 1;
}

.disabled-element {
  pointer-events: none;
  opacity: 0.4;
}

button.submit {
  padding: 6px 12px;
  font-family: monospace;
  margin: 5px;
  background-color: white;
  color: black;
  border: 2px solid black;
  display: inline-block;
  cursor: pointer;
}

button.disabled {
  padding: 6px 12px;
  font-family: monospace;
  margin: 5px;
  background-color: white;
  color: black;
  border: 2px solid black;
  display: inline-block;
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

table.simple-table, th.simple-table, td.simple-table {
  border: 1px solid;
}

table.highlighted-table, th.highlighted-table, td.highlighted-table {
  border: 1px solid;
  background-color: rgba(154, 70, 155, 0.574);
}

.offlineMapImg {
  width: 100%;
}