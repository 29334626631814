.map-container {
    position: relative;
    height: 400px;
    width: 400px;
    margin: 10px 10px 50px 10px;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: -5px;
    left: 20px;
    margin: 12px;
    border-radius: 4px;
}

.map-legend {
    position: absolute;
    top: 85%;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 5px;

}

.map-legend-item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    color:  rgb(69, 69, 69);
    gap: 5px;
}

#communities {
    height: 5%;
    width: 5%;
    border: 5px solid rgb(174, 174, 174);
    border-radius: 50%;
    display: inline-block;
}

#territories {
    border: 8px solid rgb(174, 174, 174, 0.5);
    display: inline-block;
}

.map-info {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    border-radius: 4px;
}

.layer-toggle {
    color: rgba(35, 55, 75);
    padding: 6px 12px;
    font-family: monospace;
    border-radius: 4px;
    border: none;
    margin: 5px;
    cursor: pointer;

}

.help-button {
    align-self: center;
    width: fit-content;
    padding: 6px 12px;
    margin: 5px;
    background-color: white;
    color: black;
    border: 2px solid black;
    cursor: pointer;
}